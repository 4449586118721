import React from 'react'
import './StudentClient.css'
import client from './Client.png'
import freelancer from './freelancer.png'
import { Link } from 'react-router-dom'
const StudentClient = () => {
  return (
    <div className='register_main'>
       <div className="register_master">
          <div className="register_contant">
              <div className="register_heading">
                <h2>Where Mentorship</h2>
                <h2>Meets Independence.</h2>
              </div>
              <div className="register_subheading">
                <p>Kaary: Empowering freelancers with mentorship to reach new heights, while offering businesses cost-effective solutions for a brighter future in freelancing and business success.</p>
              </div>
          </div>
          <div className="register_cli_stu">
              <div className="register_student">
              <div class="cookie-card">
    <span class="title"><b>FREELANCER</b></span>
    <p class="description">Begin your freelancing journey with Kaary. Earn while you learn from industry experts. Join us for mentorship, growth, and a successful freelance career.</p>
    <div class="actions">
        <Link to='/freelancer'>
        <button class="accept">
            READ MORE
        </button>
        </Link>
    </div>
</div>
              </div>
              <div className="register_client">
              <div class="cookie-card">
    <span class="title"><b>CLIENTS</b></span>
    <p class="description">Kaary delivers cost-effective excellence. Our platform connects you with skilled professionals who work under expert guidance, ensuring top-quality results for your projects. Elevate your business without overspending, making Kaary your go-to partner for efficient and precise project delivery.</p>
    <div class="actions actions_client">
        <Link to='/client'>
        <button class="accept">
            READ MORE
        </button>
        </Link>
    </div>
</div>
              </div>
          </div>
       </div>
    </div>
  )
}

export default StudentClient
