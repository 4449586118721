import React from 'react'
import './ClientFast_hiring.css'
import fast_hire from './kaary.png'
const ClientFast_hiring = () => {
  return (
    <div>
        <div className="fast_hiring_master">
            <div className="fast_hiring_main">
                <div>
                    <h3>Kaary: Accelerate Your Hiring Journey!</h3>
                </div>
                <div className='fast_contant'>
                <div className="fast_img">
                   <img src={fast_hire} alt="" />
                </div>
                <div className="fast_pron_list">
                      <div className="fast_list_commen">
                        <span className='title'>Cost Effective</span>
                        <p>Kaary’s solutions are market-competitive, delivering better quality at rates too good to be true.</p>
                      </div>
                      <div className="fast_list_commen">
                      <span className='title'>Elite Quality</span>
                        <p>Kaary connects clients with the best freelancers in the game to deliver superior-quality projects.</p>

                      </div>
                      <div className="fast_list_commen">
                      <span className='title'>Industry Mentored</span>
                        <p>All Kaary freelancers are mentored by industry experts who ensure no compromise on the delivered products.</p>

                      </div>
                      <div className="fast_list_commen">
                      <span className='title'>Elite Quality</span>
                        <p>Kaary connects clients with the best freelancers in the game to deliver superior-quality projects.</p>

                      </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ClientFast_hiring