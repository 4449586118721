import React from 'react'
import './FreelancerSkill.css'
const FreelancerSkill = () => {
  return (
    <div>
        <div className="freelancerskill_master">
           <div className="freelancerskill_main">
               <div className="freelancerskill_content">
                   <h2>Where Mentorship</h2>
                   <h2>Meets Independance.  </h2>
                   <p>Explore freelance opportunities, collaborate, and shape the future together. Apply now to elevate your freelance career!</p>
               </div>
               <div className="freelancerskill_list">
                    <div className="freelancerskill_box">
                        <div className="freelancerskill_card">
                            
	           <div class="card_freelancerskill">
	             	<div class="text">
	           		<span>Industry-Level Projects</span>
	           		<p class="subtitle">Gain experience in tackling real-life problems and hone your skills by working on industrial projects.</p>
	      	</div>
	         	
	           </div>
                        </div>
                        <div className="freelancerskill_card">
                        <div class="card_freelancerskill">
	             	<div class="text">
	           		<span>Expert Guidance</span>
	           		<p class="subtitle">All freelancers will be mentored by experienced industrial professionals, who shall guide you to complete the project successfully.</p>
	      	</div>
	         	
	           </div>
                        </div>
                    </div>
                    <div className="freelancerskill_box">
                        <div className="freelancerskill_card">
                        <div class="card_freelancerskill">
	             	<div class="text">
	           		<span>Grow Your Profile</span>
	           		<p class="subtitle">Get recognised for your talent, and showcase your skills on your resume. Grow your experience and work on tackling real-life projects.</p>
	      	</div>
	         	
	           </div>
                        </div>
                        <div className="freelancerskill_card">
                        <div class="card_freelancerskill">
	             	<div class="text">
	           		<span>Financial Independence</span>
	           		<p class="subtitle">Get paid to learn. Hone your skills and make an impact, all while working from your own comfort and get paid handsomely for it.</p>
	      	</div>
	         	
	           </div>
                        </div>
                    </div>
               </div>
           </div>
        </div>        
    </div>
  )
}

export default FreelancerSkill