import React, {useState, Component, useEffect} from 'react'
import './Request.css'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ClientRequirement from '../ClientRequirement/ClientRequirement';
import ClientDashboard from '../ClinetDashboard/ClientDashboard';
import ClientWorkplace from '../ClientWorkplace/ClientWorkplace';
import SidePanel from '../SidePanel/SidePanel';

const Request = () => {

   const [state, setState] = useState({
      isPaneOpen: false,
      isPaneOpenLeft: false
    });

    const [jobs, setJobs] = useState([])

    useEffect(() => {
      setJobs([
         ...jobs,
         {
            'title': 'Describle the job you want to post',
            'description': 'post job by using post job button',
            'category': 'Your need',
            'freelancingStatus': 'ready to work',
            'estimatedTime': 'ASAP',
            'estimatedPriceMin': 'Min',
            'estimatedPriceMax': 'Max'
         }
      ])
    }, [])

    const addJob = (newJob) => {
      setJobs([
         ...jobs,
         newJob
      ])

    }


  return (
    <div>
      <ClientWorkplace addJob={addJob}/>
       <div className="list_master">
        <SlidingPane
        className="some-custom-class sidepanel"
        overlayClassName="some-custom-overlay-class sidepanel"
        isOpen={state.isPaneOpen}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <SidePanel/>

        </SlidingPane>
<div className='client_posted_job post_list'>

   {/* list we have to make loop  */}
   {
      jobs.map((job) => {
         return (
            <div className="client_query" id='list' onClick={() => setState({ isPaneOpen: true })}>
             <div className="query_title">
                 <span className='title'>{job.title}</span>
                 <p>{job.description}</p>
             </div>
             <div className="query_freelancer_dis">
                <ul>
                  <li>category: {job.category}</li>
                  <li>time estimation: {job.estimatedTime}</li>
                  <li>amount: {job.estimatedPriceMin} - {job.estimatedPriceMax} $</li>
                  <li>Freelancer Status: {job.freelancingStatus}</li>
                </ul>
             </div>
          </div>
         )
      })
   }
          

{/* Loop end over here */}
    </div>
       </div>
    </div>
  )
}

export default Request


