import React from 'react'
import './Usp.css'
import usp from './freelancer.png'
const Usp = () => {
  return (
    <div>
        <div className="usp_master">
            <div className="usp_main">
                <div className="usp_list">
                     <div className="usp_title_row">
                         <div className="usp_title_box">
                             <span className='title'></span>
                             <h4>No Registration Cost.</h4>
                         </div>
                         <div className="usp_title_box">
                         <span className='title'></span>
                             <h4>Hire Skilled Professionals.</h4>
                         </div>
                     </div>
                     <div className="usp_title_row">
                         <div className="usp_title_box">
                         <span className='title'></span>
                             <h4>Start Strong.</h4>
                         </div>
                         <div className="usp_title_box">
                         <span className='title'></span>
                             <h4>Expert-Led Freelancing.</h4>
                         </div>
                     </div>
                     <div className="usp_title_row">
                         <div className="usp_title_box">
                         <span className='title'></span>
                             <h4>Earn While You Learn.</h4>
                         </div>
                         <div className="usp_title_box">
                         <span className='title'></span>
                             <h4>Affordable, High-Quality Results.</h4>
                         </div>
                     </div>
                </div>
                <div className="usp_contant">
                  <h3>Why Choose Us?</h3>
                  <h3></h3>
                  <p>Explore what makes us special at Kaary. Our distinctive advantages, such as expert mentorship and affordable quality, ensure your freelancing journey or business engagement is a success. Join us for a unique experience</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Usp
