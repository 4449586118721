import React from 'react'
import './SignUp.css'
import { Link } from 'react-router-dom'
const LogIn = () => {
  return (
      <div className="login_master">
        <div className="wrapper">
        <div className="card-switch">
            <label className="switch">
               <input type="checkbox" className="toggle"/>
               <span className="slider"></span>
               <span className="card-side"></span>
               <div className="flip-card__inner">
                  <div className="flip-card__front">
                  <form class="form">
    <p class="title">LogIn for FreeLancer</p>
    <p class="message">Signup now and get full access to our app. </p>

    <label>
        <input required="" placeholder="" type="email" class="input"/>
        <span>Email</span>
    </label> 
        
    <label>
        <input required="" placeholder="" type="password" class="input"/>
        <span>Password</span>
    </label>
    <Link style={{width: "100%"}} to='/clinetdashboard'>
    <button class="submit">Submit</button>
    </Link>
    <p class="signin">Create a new Account <Link to="/login">Signin</Link> </p>
</form>
                  </div>
                  <div className="flip-card__back">
                  <form class="form">
    <p class="title">LogIn for Client</p>
    <p class="message">Signup now and get full access to our app. </p>            
    <label>
        <input required="" placeholder="" type="email" class="input"/>
        <span>Email</span>
    </label> 
        
    <label>
        <input required="" placeholder="" type="password" class="input"/>
        <span>Password</span>
    </label>
    <Link style={{width: "100%"}} to='/clinetdashboard'>
    <button class="submit">Submit</button>
    </Link>
    <p class="signin">Create New Account <Link to='/login'>SignUp</Link> </p>
</form>
                  </div>
               </div>
            </label>
        </div>   
      </div>
     </div>
  )
}

export default LogIn
