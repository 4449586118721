import React from 'react'
import './Whatyouget.css'

const Whatyouget = () => {
  return (
    <div>
        <div className="whatyouget_master">
            <div className="whatyouget_main">
               <div className="whatyouget_title">
                    <div className='whatyouget_heading'>
                        <h3>Quality Assured</h3>
                        <h3></h3>
                    </div>
                     <div className='whatyouget_para'>
                        <p>Kaary delivers cost-effective excellence. Our platform connects you with skilled professionals who work under expert guidance, ensuring top-quality results for your projects.</p>
                     </div>
               </div>
               <div className="whatyouget_content">
                  <div className="whatyouget_box">
                     <div className="whatyouget_deatil1">
                     <div class="cookie-card">
    <span class="title"><b>FREELANCER</b></span>
    <p class="description">Begin your freelancing journey with Kaary. Earn while you learn from industry experts. Join us for mentorship, growth, and a successful freelance career.</p>

</div>
                     </div>
                     <div className="whatyouget_deatil2">
                     <div class="cookie-card">
    <span class="title"><b>FREELANCER</b></span>
    <p class="description">Begin your freelancing journey with Kaary. Earn while you learn from industry experts. Join us for mentorship, growth, and a successful freelance career.</p>

</div>
                     </div>
                  </div>
                  <div className="whatyouget_box">
                     <div className="whatyouget_deatil2">
                     <div class="cookie-card">
    <span class="title"><b>FREELANCER</b></span>
    <p class="description">Begin your freelancing journey with Kaary. Earn while you learn from industry experts. Join us for mentorship, growth, and a successful freelance career.</p>

</div>
                     </div>
                     <div className="whatyouget_deatil1">
                     <div class="cookie-card">
    <span class="title"><b>FREELANCER</b></span>
    <p class="description">Begin your freelancing journey with Kaary. Earn while you learn from industry experts. Join us for mentorship, growth, and a successful freelance career.</p>
   
</div>
                     </div>
                  </div>
               </div>
            </div>
        </div>
    </div>
  )
}

export default Whatyouget