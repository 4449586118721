import React from 'react'
import './WaitList.css'
const WaitList = () => {
  return (
    <div>
      <div className="waitlist_master">
        <div className="waitlist_main">
            <h3>Join Our Waitlist Today</h3>
            <p>Sign Up for Exclusive Launch Updates – Be the First to Know!</p>
            <form action="#">
            <input className='waitlist_input' type="email" placeholder="Your@Email.com"/>
            <input className='waitlist_button' type="button" value="Join Us"/>
            </form>
        </div>
      </div>
    </div>
  )
}

export default WaitList
