import React from 'react'
import './SidePanel.css'

const SidePanel = () => {
  return (
    <div className='panel_master'>
        <div className="panel_freelancer">
                <span style={{fontSize: '30px', textAlign: 'left'}} className='title'>Team You have alloted</span>
                <div className="freelancer_profile">
                       <div className="profile_name">
                        @Aayush saine
                       </div>
                       <div className="profilechat">
                         <div className="profile_category">
                            @Mentor
                         </div>
                         <div className="profile_getchat">
                           <a href="#">Get Chat <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path></svg></a>
                         </div>
                       </div>
                </div>
                <div className="mentor_profile freelancer_profile">
                <div className="profile_name">
                        @Rahul meena
                       </div>
                       <div className="profilechat">
                         <div className="profile_category">
                            @Freelancer
                         </div>
                         <div className="profile_getchat">
                           <a href="#">Get Chat <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path></svg></a>
                         </div>
                       </div>
                </div>
        </div>
        <div className="panel_client">
               <div className="client_job_des">
                <div className='client_job_heading'>Description</div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit sit harum amet quidem iste, dolore quis placeat omnis temporibus enim magnam exercitationem a mollitia eaque sequi, impedit ipsam pariatur unde illum at. Sapiente modi sit, debitis cupiditate consectetur fugit laborum.</div>
               <div className="client_job_detail">
               <div className='client_job_heading'>Project Details</div>
               <ul>
                  <li>category: web developement</li>
                  <li>time estimation: 3 month</li>
                  <li>amount: 5000 - 8000 $</li>
                  <li>Freelancer Status: joined</li>
                </ul>
               </div>
               <div className="client_job_stack">
               <div className='client_job_heading'>Tech Stack</div>
                <ul>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Javascript</li>
                    <li>React js</li>
                    <li>Node js</li>
                    <li>MongoDB</li>
                </ul>
               </div>
        </div>
    </div>
  )
}

export default SidePanel