import React from 'react'
import './DashboardNavbar.css'
import {Link} from 'react-router-dom'
import logo from './kaary.png'

const DashboardNavbar = () => {
  return (
    <div>
            <div className='Nav_master'>
      <div className="Navbar">
        <div className="navlogo">
           <img src={logo} alt="" />
        </div>
        <div className="navmenu">
            <ul>
                <Link to='/'><li>Home</li></Link>
                <Link to='/client'><li>Chat</li></Link>
                <Link to="/freelancer"><li>Support</li></Link>
            </ul>
        </div>
        <div style={{width: '22%',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'end'}} className="navsignin">
          <span>@Yourname</span>
        </div>
      </div>
    </div>
    </div>
  )
}

export default DashboardNavbar