import React from 'react'
import './FreelancerMaster.css'
import master from './webmaster_img.png'

const FreelancerMaster = () => {
  return (
    <div>
        <div className="freelancer_master">
            <div className="freelancer_main">
                <div className='freelancermaster_heading'>
                    <h2>Where Mentorship Meets Independance.</h2>
                    <p></p>
                </div>
                <div className='freelancermaster_info'>
              <div className="freelancermaster_contant">
                      <div className="freelancermaster_list">
                      <div className="usp_title_box freelancermaster_box">
                             <span className='title'></span>
                             <h4>Industry-Level Projects</h4>
                         </div>  
                      <div className="usp_title_box freelancermaster_box">
                             <span className='title'></span>
                             <h4>Start Strong</h4>
                         </div>  
                      <div className="usp_title_box freelancermaster_box">
                             <span className='title'></span>
                             <h4>Earn while you Learn</h4>
                         </div>  
                      <div className="usp_title_box freelancermaster_box">
                             <span className='title'></span>
                             <h4>Financial Independence</h4>
                         </div>  
                      </div>  
              </div>
              <div className="freelancermaster_img">
                 <img src={master} alt="" />
              </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default FreelancerMaster