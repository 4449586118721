import React, { useState } from 'react'
import './ClientRequirement.css'
import Request from '../Request/Request';
const ClientRequirement = ({open,onClose, addJob}) => {

    const [newJob, setNewJob] = useState({
      'title': 'Job Title 2',
      'description': 'Job Desc 2',
      'category': 'Web Development',
      'freelancingStatus': 'Searching',
      'estimatedTime': '4 months',
      'estimatedPriceMin': '5000',
      'estimatedPriceMax': '8000'
    }) 

    const changeField = (fieldName, fieldValue) => {
        setNewJob({
          ...newJob,
          [fieldName]: fieldValue
        })
    }

    if(!open) return null;
  return (
    <div>
      <div className="requirement_master">
        <div className="requirement_main">
           <div className="fill_client_form">
            <div className="fill_form">
               <form action="#">
               <div className="form__group field">
                 <input type="input" className="form__field" placeholder="JOB TITLE" required="" onChange={(e) => changeField('title', e.target.value)}/>
                 <label for="name" className="form__label">JOB TITLE</label>
               </div>
               <div className="dropdown_client_form">
                   <select name="" id="" onChange={(e) => changeField('category', e.target.value)}>
                     <option value="">Category</option>
                     <option value="">Web Development</option>
                     <option value="">Content Writing</option>
                     <option value="">Video Editing</option>
                     <option value="">Graphic desiging</option>
                   </select>                 
               </div>
               <div className="dropdown_client_form">
                   <select name="" id="" onChange={(e) => changeField('estimatedTime', e.target.value)}>
                     <option value="">Estimated Time</option>
                     <option value="">1 Months</option>
                     <option value="">3 Months</option>
                     <option value="">5 Months</option>
                     {/* <option value="">Graphic desiging</option> */}
                   </select>                 
               </div>
               <span className='pricing'>
               <div className="form__group field">
                 <input type="number" className="form__field min-price" placeholder="MIN-PRICE" required="" onChange={(e) => changeField('estimatedPriceMin', e.target.value)}/>
                 <label for="name" className="form__label">MIN-PRICE</label>
               </div>
               <div className='to'>-to-</div>
               <div className="form__group field max-price">
                 <input type="number" className="form__field" placeholder="MAX-PRICE" required=""onChange={(e) => changeField('estimatedPriceMax', e.target.value)}/>
                 <label for="name" className="form__label">MAX-PRICE</label>
               </div>
               </span>
               <div className="form__group field textarea_client">
                <textarea name="" id="" cols='100' placeholder='Write your opinion' onChange={(e) => changeField('description', e.target.value)}></textarea>
               </div>
              <input type="file" />
               </form>
            </div>
           </div>
           <div className="drag_file">
              <div className="fill_heading">
                <h3>Lorem ipsum dolor </h3>
                <h3>Lorem, ipsum.</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, asperiores?</p>
                </div>
                <div className="paymentbtn">
          <button className='navbutton heading_button' onClick={() => addJob(newJob)}>
            <span>Continue</span>
          </button>
                <button onClick={onClose} className='navbutton heading_button'>
            <span>Close</span>

          </button>
                </div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default ClientRequirement
