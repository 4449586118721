import React from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import NavBar from './components/NavBar/NavBar.jsx'
import './App.css'
import Hero from './components/Hero/Hero.jsx'
import StudentClient from './components/StudentClient/StudentClient.jsx'
import Usp from './components/Usp/Usp.jsx'
import WaitList from './components/Waitlist/WaitList.jsx'
import Footer from './components/Footer/Footer.jsx'
import LogIn from './components/LogIn/LogIn.jsx'
import SignUp from './components/SignUp/SignUp.jsx'
import ClientDashboard from './components/ClinetDashboard/ClientDashboard.jsx'
import Request from './components/Request/Request.jsx'
import ClientFast_hiring from './components/ClientFast_hiring/ClientFast_hiring.jsx';
import Whatyouget from './components/Whatyouget/WhatyouGet.jsx';
import ClientFooter from './components/Client_jobfooter/ClientFooter.jsx';
import FreelancerHero from './components/Freelancer_hero/FreelancerHero.jsx';
import FreelancerSkill from './components/Freelancer_skill/FreelancerSkill.jsx';
import FreelancerMaster from './components/Freelancer_Master/FreelancerMaster.jsx';
import FreelancerFooter from './components/Freelancer_footer/FreelancerFooter.jsx';
import ClientWorkplace from './components/ClientWorkplace/ClientWorkplace.jsx';
import DashboardNavbar from './components/DashboardNavbar/DashboardNavbar.jsx';


const App = () => {
  return (
    <BrowserRouter>
    <div className='kaary'>
      <Routes>
        <Route path='/' element={<>
          <NavBar/>
           <Hero/>
           <StudentClient/>
           <Usp/>
           <WaitList/>
           </>
           }/>
           <Route path='/login' element={<>
                  <NavBar/>
        <LogIn/>
        </>}/>
           <Route path='/signup' element={<>
            <NavBar/>
        <SignUp/>
        </>}/>
        <Route path='/client' element={
             <>
             <NavBar/>
             <ClientDashboard/>
             {/* <Request/> */}
             <ClientFast_hiring/>
             <Whatyouget/>
             <ClientFooter/>
             </>
            }/>
        <Route path='/freelancer' element={<>
          <NavBar/>
          <FreelancerHero/>
          <FreelancerSkill/>
          <FreelancerMaster/>
          <FreelancerFooter/>
        </>}/>
        <Route path='/clinetdashboard' element={
          <>
            <DashboardNavbar/>
            <Request/>
          </>
           }/>
           </Routes> 
           <Footer/>
           
    </div>
    </BrowserRouter>
  )
}

export default App
